.youload_footer_section{
    padding:50px 0;
    background-color: #404041;
    color:#fff;
}
.footer_title_text{
    font-size: 20px;
    width: auto;
    line-height: 28.8px;
    font-weight: 500;
    display: inline-block;
    margin-right: 5px;
}
.footer_social_links{
    display: inline; 
}
.footer_social_links a{
    padding-right: 10px;
}
.footer_social_links span{
    display: inline-block;
    margin-right: 5px; 
    border-radius: 50%;
    /* background-color: #fff; */
    text-align: center;
    color:#000;
    font-size: 13px;

}
.bg_icon_fo{
    background-color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: inline-block; 
    border-radius: 50%;
    margin-top: 8px;
}
.contact_us_div{
    padding:20px 0;
}
.contact_sub_title{
    margin-bottom: 0;
}
.footer_hr{
    height: 2px;
    background-color: #C0BDBD;

}
.contact_sub_title_two{
    margin-bottom: 20px;
    font-size: 18px;
}
.footer_btn{
    background-color: #9A383C;
    padding:10px 20px;
    border:none;
    border-radius: 10px;
    color:#fff;
}
.contact_input input, .contact_input textarea{
    background-color: #4A4A4A;
    border:none;
    outline: none;
    width: 100%;
    margin-bottom: 15px;
    padding:6px 10px;
    border-radius: 6px;
}
.contact_input textarea{
    height: 296px;
}
.contact_input input::placeholder, .contact_input textarea::placeholder {
    color:#fff;
}
.youload_copyright{
    padding:15px 0;
    background-color: #373738;
}

@media (max-width: 576px){
    .twitter_embedded{
        margin-top: 25px;
        margin-bottom: 20px;
    }
    .footer_contact_us, .footer_get_in_touch{
        padding: 20px;
    }
}